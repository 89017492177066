import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import NewsletterBanner from "../../components/newsletter-banner";

const AboutEllaPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Ellas-Life-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			earlyYearsImg: wpMediaItem(title: { eq: "The-Early-Years" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			everythingChangesImg: wpMediaItem(title: { eq: "Everything-Changes" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			theNextTwoImg: wpMediaItem(title: { eq: "The-Next-Two-Years" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			over30Img: wpMediaItem(title: { eq: "Over-30" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Ella",
				item: {
					url: `${siteUrl}/about-ella`,
					id: `${siteUrl}/about-ella`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="About Ella | The Ella Roberta Foundation"
				description="Ella is the first person in the world to have air pollution listed as a cause of death on her death certificate."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-ella`,
					title: "About Ella | The Ella Roberta Foundation",
					description:
						"Ella is the first person in the world to have air pollution listed as a cause of death on her death certificate.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<div className="bg-background">
					<Hero
						title={
							<span>
								ELLA'S LIFE
								<br className="d-none d-md-block" /> AND HER LEGACY
							</span>
						}
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
					<section className="bg-primary py-5 pt-lg-8">
						<Container>
							<Row className="justify-content-center">
								<Col lg={9}>
									<p className="text-white fs-3  text-center position-relative">
										<span
											style={{ left: "-2.6rem", top: "-2rem" }}
											className="display-2 d-none d-lg-inline-block me-5 position-absolute gobold-thin text-uppercase "
										>
											“
										</span>
										<span className="d-lg-none gobold-thin">“</span>
										My daughter Ella was a playful, happy child growing up in
										South East London. Healthy at birth, with a lust for life,
										she didn’t develop asthma until just before her seventh
										birthday. A few weeks after her ninth birthday, she suffered
										a fatal asthma attack. Ella is the first person in the world
										to have air pollution listed as a cause of death on her
										death certificate.{" "}
										<span className="d-lg-none gobold-thin">”</span>
										<span
											style={{ right: "-2.8rem", top: "-2rem" }}
											className="display-2 d-none d-lg-inline-block position-absolute gobold-thin text-uppercase"
										>
											”
										</span>
									</p>
									<p className="text-center text-white fs-5">
										Rosamund Adoo Kissi Debrah
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="bg-white py-5 ">
						<Container>
							<Row className="justify-content-center">
								<Col className="text-center">
									<h2 className="fs-1 text-primary pb-5">THIS IS HER STORY</h2>
								</Col>
							</Row>
							<Row className="gx-lg-10 align-items-center">
								<Col lg={8}>
									<h3 className="fs-2">THE EARLY YEARS</h3>
									<p>
										Ella was born in 2004 in Lewisham Hospital, South East
										London. At 8lbs, she was a healthy baby girl. Energetic and
										fun-loving, she grew up in London with a keen interest in
										sport, especially enjoying time she spent in the swimming
										pool. She started ‘Little Kickers’ at Millwall FC when she
										was just two, and by four she had graduated to gymnastics.
									</p>
									<p>
										Ella also loved books, and had a reading age well beyond her
										years. Her creativity and love of music meant she spent many
										happy hours playing different instruments and making up
										songs - often taking a starring role in shows put on by
										Jigsaw Performing Arts, the local drama and dance school.
										Her big dream, however, was to be an air ambulance doctor
										and to rescue people from tricky spots, something she was
										inspired to be after seeing the Red Arrows fly by.
									</p>
								</Col>{" "}
								<Col
									className="mb-5 mb-lg-0"
									xs={{ span: 12, order: "first" }}
									lg={{ span: 4, order: "last" }}
								>
									<div
										style={{
											boxShadow: "0px 3px 70px #00000029",
											borderRadius: "5px",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											class="w-100"
											image={
												data.earlyYearsImg?.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={data.earlyYearsImg?.altText}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="bg-background-dark py-5 ">
						<Container>
							<Row className="align-items-center gx-lg-5">
								<Col
									className="mb-5 mb-lg-0"
									xs={{ span: 12 }}
									lg={{ span: 4 }}
								>
									<div
										style={{
											boxShadow: " 0px 3px 99px #000000C4",
											borderRadius: "5px",
											overflow: "hidden",
										}}
										className="position-relative"
									>
										<div
											style={{ zIndex: 3 }}
											className="position-absolute start-0 top-0 d-flex align-items-center justify-content-center team-link h-100 w-100"
										>
											<div
												style={{ opacity: 0.5, backgroundColor: "#0000FF" }}
												className="position-absolute w-100 h-100 top-0 start-0 "
											></div>
											<p className="text-white fs-3 mx-lg-5 text-center position-relative">
												<span
													style={{ left: "-2.6rem", top: "-2rem" }}
													className="display-4 d-none d-lg-inline-block me-5 position-absolute gobold-thin text-uppercase "
												>
													“
												</span>
												<span className="d-lg-none gobold-thin">“</span>
												Those that don't believe
												<br /> in magic will never
												<br /> find it.
												<span className="d-lg-none gobold-thin">”</span>
												<span
													style={{ right: "-2.6rem", top: "-2rem" }}
													className="display-4 d-none d-lg-inline-block position-absolute gobold-thin text-uppercase"
												>
													”
												</span>
											</p>
										</div>
										<GatsbyImage
											class="w-100"
											image={
												data.everythingChangesImg?.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={data.everythingChangesImg?.altText}
										/>
									</div>
								</Col>
								<Col className="text-white" lg={8}>
									<h2 className="fs-2">
										EVERYTHING CHANGES WHEN ILLNESS BEGINS…
									</h2>
									<p>
										Three months before her seventh birthday, Ella developed a
										chest infection that turned into a persistent cough. It
										sounded a lot like whooping cough but a doctor diagnosed her
										with asthma. Rosamund, her mum, didn’t initially worry at
										the mention of this common childhood complaint - 5.4 million
										people in the UK are asthmatic, 1.1 million of them children
										- and life continued as normal.
									</p>
									<p>
										Now in Year 2, Ella was continuing to enjoy her busy and
										fun-packed life. She easily made friends at school, and felt
										secure at home, cared for by a loving mother and revelling
										in the attention and adoration of her younger twin siblings.
									</p>
									<p>
										That changed when, soon after the asthma diagnosis, Ella
										coughed so much she blacked out; she had suffered a
										‘coughing syncope’ caused by lack of oxygen to the brain.
										Fortunately a neighbour with first aid skills was on hand to
										resuscitate her. She was admitted into hospital and released
										the following day, but a week later it happened again. This
										time she was put in a medically induced coma and woke up
										three days later. She went from being a happy, healthy child
										to being disabled by asthma.
									</p>
								</Col>{" "}
							</Row>
						</Container>
					</section>
					<section className=" py-5 py-lg-7 bg-background">
						<Container>
							<Row className="gx-lg-10  align-items-center">
								<Col lg={7}>
									<p>
										The next two years were filled with a barrage of tests to
										find out what was causing Ella’s ill health. These
										examinations ranged from sleep and bacterial studies, to
										tests for everything from epilepsy to cystic fibrosis. They
										only revealed she was atopic (sensitive to allergens) but
										couldn’t ascertain the cause of her asthma.
									</p>
									<p>
										{" "}
										In 2012 she was put in the care of Dr Colin Wallace, a
										respiratory consultant at Great Ormond Street Hospital, and
										was well enough to enjoy a summer full of activities and
										fun, which included the 2012 London Olympics. However, the
										coughing returned in September and Ella was in and out of
										hospital.
									</p>
								</Col>{" "}
								<Col
									className="mb-5 mb-lg-0"
									xs={{ span: 12, order: "first" }}
									lg={{ span: 5, order: "last" }}
								>
									<div
										style={{
											boxShadow: "0px 3px 70px #0000004D",
											borderRadius: "5px",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											class="w-100"
											image={
												data.theNextTwoImg?.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={data.theNextTwoImg?.altText}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section
						style={{ boxShadow: "0px 3px 70px #0000004D" }}
						className="py-5 bg-primary"
					>
						<Container>
							<Row className="justify-content-center">
								<Col lg={9}>
									<p className="text-white text-center fs-3">
										A life cut short. On 15th February 2013, just three weeks
										after her ninth birthday, Ella died of a fatal asthma
										attack.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="bg-background-dark py-5 mt-5">
						<Container>
							<Row className="align-items-center gx-lg-5">
								<Col
									className="mb-5 mb-lg-0"
									xs={{ span: 12 }}
									lg={{ span: 4 }}
								>
									<div
										style={{
											boxShadow: " 0px 3px 99px #000000C4",
											borderRadius: "5px",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											class="w-100"
											image={
												data.over30Img?.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={data.over30Img?.altText}
										/>
									</div>
								</Col>
								<Col className="text-white" lg={8}>
									<p>
										She had over 30 emergency hospital admissions between the
										first diagnosis of asthma and her death, just over two years
										later. Throughout her short life and illness, there had been
										no mention of air pollution being a possible factor in
										relation to her illness.
									</p>
									<p>
										On Ella’s original death certificate it said she died of
										acute respiratory failure. At no point in her illness did
										any medical professional mention that air pollution could be
										an asthma trigger, alongside traditional triggers such as
										pollen or the weather.
									</p>
								</Col>{" "}
							</Row>
						</Container>
					</section>
					<section
						id="legacy"
						className="py-5  bg-background position-relative"
					>
						<Container>
							<Row>
								<Col>
									<h2 className="display-3 pb-5 text-primary text-center pb-5 heading-text">
										ELLA’S
										<br /> LEGACY
									</h2>
									<h3>THE FOUNDATION</h3>
									<p className="mb-5">
										Rosamund was determined that some good should result from
										her daughter’s premature death, but didn’t know where to
										start. It was Ella’s consultant, Dr Wallace, who first
										suggested to Rosamund that a Foundation may be a fitting way
										to commemorate Ella and improve the lives of children with
										asthma in South East London. The Ella Roberta Foundation was
										launched at Ella’s primary school on what would have been
										Ella’s tenth birthday.
									</p>
									<h3>POLLUTION MATTERS</h3>
									<p>
										It was then that Rosamund began to realise there may be a
										link between her daughter’s death and air pollution. A local
										resident contacted her after a piece was published in the
										local paper, and suggested she should; “have a look at the
										air pollution levels on the night Ella died”. Rosamund did
										some research and discovered that the heavily congested
										South Circular, near where they lived, had illegal levels of
										nitrogen dioxide caused by traffic.
									</p>
									<p className="mb-5">
										At the same time Professor Sir Stephen Holgate, a consultant
										respiratory physician at the University of Southampton, was
										examining Ella’s medical papers and coming to a similar
										conclusion. He reported that in his opinion the severity of
										her asthma, and also her death, were linked to the high
										levels of air pollution in the area where she lived.
									</p>
									<h3 id="inquest">A NEW INQUEST</h3>
									<p>
										The impact of air pollution had never been previously
										considered by medics caring for Ella and was not looked into
										at the inquest into her death, so Rosamund decided to seek
										legal advice. She started to work with Jocelyn Cockburn, a
										human rights lawyer, soon afterwards.
									</p>
									<p>
										Because of the new evidence that cast doubt on Ella’s cause
										of death, in January 2019 the Attorney General, Geoffrey Cox
										QC MP gave his consent for the family to apply to the High
										Court for a fresh inquest into Ella’s death. Rosamund
										subsequently applied to the High Court to quash the findings
										of the first inquest and a second inquest was granted.
									</p>
									<p className="mb-5">
										The new inquest, in December 2020, looked at the role air
										pollution played in her death. It examined what government
										departments, local authorities and the then Mayor of London
										had done to reduce illegal levels of air pollution where
										Ella lived. It became apparent that throughout Ella’s life,
										nitrogen dioxide emissions in Lewisham exceeded both EU and
										national levels and particulate matter levels were above the
										World Health Organisation (WHO) guidelines.
									</p>
									<h3>THE FINDINGS</h3>
									<p>
										At the end of a nine day inquest the Coroner, Philip Barlow,
										concluded:
									</p>
									<p>
										“Air pollution was a significant contributory factor to both
										the induction and exacerbations of her asthma. During the
										course of her illness between 2010 and 2013 she was exposed
										to levels of nitrogen dioxide (N02) and particulate matter
										in excess of World Health Organisation Guidelines. The
										principal source of her exposure was traffic emissions.
										During this period there was a recognised failure to reduce
										the level of NO2 to within the limits set by EU and domestic
										law which possibly contributed to her death. Ella's mother
										was not given information about the health risks of air
										pollution and its potential to exacerbate asthma. If she had
										been given this information she would have taken steps which
										might have prevented Ella's death.”
									</p>
									<p>
										His conclusion was: “Ella died of asthma contributed to by
										exposure to excessive air pollution”.
									</p>
									<p>
										His narrative findings included that “excess levels of
										nitrogen dioxide and particulate matter, PM10 and PM2.5,
										were a health risk especially to children with asthma” and
										that “the health impacts of air pollution have been
										acknowledged for many years .”
									</p>
									<p>
										The case made legal history by ruling that air pollution was
										one of the causes of the nine-year-old’s death.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section id="prevention" className="py-5 mt-5 bg-primary">
						<Container>
							<Row>
								<Col>
									<h2 className="display-3 pb-5 text-white text-center pb-5 heading-text">
										PREVENTION OF
										<br className="d-none d-md-block" /> FUTURE DEATHS
									</h2>
									<p className="text-white">
										Occasionally, the evidence from an inquest will show that
										there is a risk that future deaths could occur and in these
										cases the Coroner must publish a{" "}
										<a
											className="white-link text-decoration-underline"
											href="https://www.judiciary.uk/wp-content/uploads/2021/04/Ella-Kissi-Debrah-2021-0113-1.pdf"
											target="_blank"
											rel="noreferrer"
										>
											Prevention of Future Deaths Report
										</a>{" "}
										(PFD) so these risks can be addressed by those responsible.
										This happened in Ella’s case and in April 2021, the coroner
										reported three concerns which pose an ongoing risk to life:
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-8">
						<Container>
							<Row className="mb-5">
								<Col>
									<p>
										<span className="display-5 me-3 gobold-thin">1.</span>{" "}
										National air pollution limits for particulate matter are set
										at a far higher level than World Health Organisation (WHO)
										guidelines. The WHO guidelines should be seen as the minimum
										requirements. Legally binding targets based on these levels
										would reduce the number of deaths.
									</p>
								</Col>
							</Row>
						</Container>
						<Container>
							<Row className="justify-content-end mb-5 mb-lg-7">
								<Col lg={10} className=" text-white">
									<div
										className="bg-black w-100 h-100 p-md-5 p-4 "
										style={{ boxShadow: "0px 3px 70px #707070" }}
									>
										<p>
											<span className="display-5 me-3 gobold-thin">2.</span>{" "}
											There is low public awareness about air pollution. Greater
											awareness would help people reduce their exposure to air
											pollution. The issue needs to be available at a national
											as well as a local government level including increasing
											capacity to monitor air quality.
										</p>
									</div>
								</Col>
							</Row>
						</Container>
						<Container fluid className="px-lg-0 mx-lg-0">
							<Row className="justify-content-start mx-lg-0 px-lg-0 mb-5 mb-lg-7">
								<Col lg={8} className="mx-lg-0 px-lg-0  text-white">
									<div
										className="bg-primary w-100 h-100 p-md-5 p-4 ps-md-6"
										style={{ boxShadow: " 0px 3px 99px #000000C4" }}
									>
										<p>
											<span className="display-5 me-3 gobold-thin">3.</span>{" "}
											Patients are not being advised about the impact of air
											pollution on health and this needs to be addressed in
											medical and nursing training and education at
											undergraduate, postgraduate and professional guidelines
											levels.
										</p>
									</div>
								</Col>
							</Row>
						</Container>
						<Container>
							<Row>
								<Col>
									<p>
										The Coroner’s PFD provides powerful leverage for change. He
										has put the Government, and all of us, on notice that a
										failure to reduce air pollution to safe levels and increase
										understanding of the health risks posed by air pollution
										will cost lives.
									</p>
									<p>
										For more on how what we do relates to the Coroner’s report,
										please visit:
									</p>
									<div className="text-center">
										<Button
											className="fs-5 w-100 w-md-auto px-5 mt-4 py-2"
											variant="outline-black"
											to="/about-us/our-work"
											as={Link}
										>
											OUR MISSION
										</Button>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<NewsletterBanner />
				</div>
			</Layout>
		</>
	);
};

export default AboutEllaPage;
